import {
  Autocomplete,
  TextField,
  Button,
  Checkbox,
  Divider,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { PagableFilter } from "../../../dto/ApplicationDTO.ts";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import TuneIcon from "@mui/icons-material/Tune";
import SearchIcon from "@mui/icons-material/Search";
import Popover from "@mui/material/Popover";
import CheckIcon from "@mui/icons-material/Check";
import MenuItem from "@mui/material/MenuItem";
import {
  getAlertTypes,
  getUserFilter,
  getUsersList,
  setUserFilter,
} from "../../../service/Endpoints.js";
import { UserDetailResponse } from "../../../dto/ResponeDTO.ts";
import { useOktaAuth } from "@okta/okta-react";
import useHttp from "../../../hooks/useHttp.js";
import { PageLoaderContext } from "../../utils/PageLoader";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { useNotification } from "../../utils/Notification/NotificationContext.jsx";
import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from '@mui/material/Tooltip';
let usersListArchive = [];
const FilterComponent = ({ onSearch,notFilter }) => {
  //State---------------------------------------------------------------------------------
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [alertId, setAlertId] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterName, setFilterName] = useState("");
  const [alertTypeOption, setAlertTypes] = useState([]);
  const [usersListOption, setUsersList] = useState([]);
  const [savedFilter, setSavedFilter] = useState({});
  const [filterAction, setFilterAction] = useState(0);
  //---------------------------------------------------------------------------------State
  //hooks---------------------------------------------------------------------------------
  const { authState, oktaAuth } = useOktaAuth();
  const { isLoading, error, sendRequest: callApi } = useHttp();
  const { showNotification } = useNotification();
  //---------------------------------------------------------------------------------hooks

  //Context---------------------------------------------------------------------------------
  const pageLoaderContext = useContext(PageLoaderContext);
  //---------------------------------------------------------------------------------Context

  //API Calls---------------------------------------------------------------------------------
  const getUserListFromAPI = React.useCallback(() => {
    let token = authState.accessToken.accessToken;
    callApi(
      { url: getUsersList() },
      (responseData) => {
        const userDetailResp = new UserDetailResponse();
        userDetailResp.applyData(responseData);
        let data = userDetailResp.users.map((user) => {
          return { key: user.userId, value: user.name };
        });
        usersListArchive = data;
        setUsersList(data);
      },
      token
    );
  });
  const getAlertTypesFromApi = React.useCallback(() => {
    let token = authState.accessToken.accessToken;
    callApi(
      { url: getAlertTypes() },
      (responseData) => {
        setAlertTypes(responseData);
      },
      token
    );
  });

  const getUserFilterFromApi = React.useCallback(() => {
    let token = authState.accessToken.accessToken;
    callApi(
      { url: getUserFilter() },
      (response) => {
        setSavedFilter(response);
      },
      token
    );
  });
  const saveUserFilter = React.useCallback((pagableFilter) => {
    let token = authState.accessToken.accessToken;
    pagableFilter["filterName"]=filterName
    callApi(
      {
        url: setUserFilter(),
        body: pagableFilter,
        headers: { "Content-Type": "application/json" },
        method: "POST",
      },
      (response) => {
        showNotification("Filter Saved");
        getUserFilterFromApi();
      },
      token
    );
  });
  //---------------------------------------------------------------------------------API Calls

  //Constants---------------------------------------------------------------------------------
  const statusOptions = ["New", "Assigned"];
  const checkedParam = {
    icon: <CheckBoxOutlineBlankIcon fontSize="small" />,
    checkedIcon: <CheckBoxIcon fontSize="small" />,
  };
  const filterTextParam = {
    variant: "outlined",
    sx: { width: { xs: "100%", md: 200 }, borderRadius: "10px" },
    InputLabelProps: { style: { fontSize: "12px" } },
  };
  const filterAutoParam = {
    multiple: true,
    size: "small",
    limitTags: 2,
    disableCloseOnSelect: true,
    getOptionLabel: (option) => option,
  };
  const filterBtnParam = {
    sx: {
      height: "30px",
      marginLeft: "1px",
      color: "#000000",
      "&:hover": { backgroundColor: "#000000", color: "white" },
    },
  };
  //---------------------------------------------------------------------------------Constants

  //SideEffects---------------------------------------------------------------------------------
  useEffect(() => {
    pageLoaderContext.showLoader(isLoading);
  }, [isLoading]);

  useEffect(() => {
    getAlertTypesFromApi();
    getUserFilterFromApi();
    if (usersListArchive.length > 0) {
      setUsersList(usersListArchive);
    } else {
      getUserListFromAPI();
    }
  }, []);
  useEffect(() => {
    error && showNotification(error,"error");
  }, [error]);
  //---------------------------------------------------------------------------------SideEffects

  //Functions---------------------------------------------------------------------------------
  const createNewPagableFilter = () => {
    const pagableFilter = new PagableFilter();
    selectedTypes.length > 0 &&
      pagableFilter.addFilter("alertType", selectedTypes.toString(), "in");
    selectedStatus.length > 0 &&
      pagableFilter.addFilter("status", selectedStatus.toString(), "in");
    selectedAssignees.length > 0 &&
      pagableFilter.addFilter(
        "userId",
        usersListOption.filter(user=>selectedAssignees.includes(user.value)).map((user) => user.key).toString(),
        "in"
      );
    alertId && pagableFilter.addFilter("alertId", alertId);
    return pagableFilter;
  };
  const onSearchHandler = () => {
    onSearch(createNewPagableFilter());
  };
  const onClearSearchHandler = () => {
    const pagableFilter = new PagableFilter();
    setSelectedTypes([]);
    setSelectedStatus([]);
    setSelectedAssignees([]);
    setAlertId("");
    onSearch(pagableFilter);
  };

  const onPopOverHandler = (event, filterAction) => {
    setAnchorEl(event.currentTarget);
    setFilterAction(filterAction);
    setFilterName("");
  };

  const onConfirmFilterSaveHandler = (e) => {
    if (filterName.trim() !== "") {
      const pagableFilter = createNewPagableFilter();
      saveUserFilter(pagableFilter);
    }
    setAnchorEl(null);
  };

  const handleSaveFilterPopClose = () => {
    setAnchorEl(null);
    setFilterName("");
  };

  const handleUserFilterSelected = (value) => {
    setSelectedTypes([]);
    setSelectedStatus([]);
    setSelectedAssignees([]);
    setAlertId("");
    const selectedUsrFilter = savedFilter.filters[value];
    selectedUsrFilter.forEach((filter) => {
      const valueArray = filter.value.split(",").map((item) => item.trim());
      if (filter.field == "alertType" && !notFilter.includes(1)) {
        setSelectedTypes(valueArray);
      }
      if (filter.field == "status" && !notFilter.includes(2)) {
        setSelectedStatus(valueArray);
      }
      if (filter.field == "userId" && !notFilter.includes(3)) {
        setSelectedAssignees(
          usersListOption.filter((user) => valueArray.includes(user.key)).map(user=>user.value)
        );
      }
      if (filter.field == "alertId" && !notFilter.includes(4)) {
        setAlertId(filter.value);
      }
      setAnchorEl(null);
    });
  };
  //---------------------------------------------------------------------------------Functions
  return (
    <>
      <div className="bg-white p-[10px] rounded-xl border-[1px] mb-[5px] shadow-inner border-purple-200 w-fit m-auto">
        <div
          className="flex flex-row flex-wrap justify-between items-center"
          style={{ gap: "10px" }}
        >
          <div id="filter-icon" className="flex-shrink-0 my-auto">
            <TuneIcon />
          </div>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ display: { xs: "none", md: "block" }, marginX: 1 }}
          />
          <div className="flex-shrink-0 shadow-inner border-purple-300 rounded-[5px] border-[2px] p-1 w-fit">
          <Tooltip title="Load filter">
          <Button
            {...filterBtnParam}
            onClick={(event) => onPopOverHandler(event, 1)}
          >
            <DriveFolderUploadIcon />
          </Button>
          </Tooltip>
          <Tooltip title="Save Filter">
          <Button
              {...filterBtnParam}
              onClick={(event) => onPopOverHandler(event, 0)}
            >
              <SaveAsIcon />
            </Button>
            </Tooltip>
            </div>
          <Autocomplete
            {...filterAutoParam}
            options={alertTypeOption}
            value={selectedTypes}
            onChange={(event, newValue) => setSelectedTypes(newValue)}
            renderOption={(props, option, { selected },idx) => (
              <li key={idx} {...props} style={{ fontSize: "12px", height: "30px" }}>
                <Checkbox {...checkedParam} checked={selected} />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} {...filterTextParam} label="Alert Type" />
            )}
          />
          {!notFilter.includes(2)&&
          <Autocomplete
            {...filterAutoParam}
            options={statusOptions}
            value={selectedStatus}
            onChange={(event, newValue) => setSelectedStatus(newValue)}
            renderOption={(props, option, { selected },idx) => (
              <li key={idx} {...props} style={{ fontSize: "12px", height: "30px" }}>
                <Checkbox id={idx} {...checkedParam} checked={selected} />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} {...filterTextParam} label="Status" />
            )}
          />}
          {!notFilter.includes(3)&&
          <Autocomplete
            {...filterAutoParam}
            options={usersListOption.map((user) => user.value)}
            value={selectedAssignees}
            onChange={(event, newValue) => setSelectedAssignees(newValue)}
            renderOption={(props, option, { selected },idx) => (
              <li key={idx} {...props} style={{ fontSize: "12px", height: "30px" }}>
                <Checkbox {...checkedParam} checked={selected} />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} {...filterTextParam} label="Assignee" />
            )}
          />}

          <TextField
            size="small"
            variant="outlined"
            label="Alert Id"
            value={alertId}
            onChange={(e) => setAlertId(e.target.value)}
            sx={{ width: { xs: "100%", md: 200 } }}
            InputLabelProps={{
              style: { fontSize: "12px" },
            }}
          />

          <Divider
            orientation="vertical"
            flexItem
            sx={{ display: { xs: "none", md: "block" }, marginX: 1 }}
          />
          <div className="flex-shrink-0 shadow-inner border-violet-300 rounded-[5px] border-[2px] p-1 w-fit">
          <Tooltip title="Apply Filter">
            <Button {...filterBtnParam} onClick={onSearchHandler}>
              <SearchIcon />
            </Button>
            </Tooltip>
            <Tooltip title="Reset Filter">
            <Button {...filterBtnParam} onClick={onClearSearchHandler}>
              <RefreshIcon />
            </Button>
            </Tooltip>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleSaveFilterPopClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              {filterAction == 0 ? (
                <TextField
                  placeholder="Save As"
                  variant="outlined"
                  value={filterName}
                  onChange={(event) => setFilterName(event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={onConfirmFilterSaveHandler}>
                          <CheckIcon style={{ color: "green" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                savedFilter.filters!=null?
                <>
                    {Object.keys(savedFilter.filters).map((data,idx) => (
                      <MenuItem id={idx} value={data} onClick={()=>handleUserFilterSelected(data)}>{data}</MenuItem>
                    ))}
                </>:<div className="p-1 text-xs text-red-300">No Saved Filter</div>
              )}
            </Popover>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterComponent;
