import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

function FilterPanel({
  columns,
  onFilterChange,
  handleQuickFilterChange,
  onClearFilter,
}) {
  const [selectedColumn, setSelectedColumn] = React.useState(columns[0].field);
  const [filterValue, setFilterValue] = React.useState("");
  const [operator, setOperator] = React.useState("contains");
  const [dateOperator, setDateOperator] = React.useState("equals");
  const [dateValue, setDateValue] = React.useState(null);

  const handleColumnChange = (event) => {
    setSelectedColumn(event.target.value);
    setFilterValue("");
    setDateValue(null);
  };
  React.useEffect(() => {
    handleQuickFilterChange(selectedColumn, filterValue, operator, dateValue);
  }, [filterValue, dateValue]);

  const handleFilterValueChange = (event, type) => {
    if (type == "Select") setOperator("equals");
    else setOperator("contains");
    setFilterValue(event.target.value);
  };

  const handleOperatorChange = (event) => {
    setDateOperator(event.target.value);
  };

  const handleDateChange = (newValue) => {
    setDateValue(newValue);
  };

  const applyFilter = () => {
    if (selectedColumn && (filterValue || (dateValue && dateOperator)))
      onFilterChange(selectedColumn, filterValue, operator, dateValue);
  };

  const clearFilter = () => {
    if (filterValue || dateValue) {
      setFilterValue("");
      setDateValue(null);
      onClearFilter();
    }
  };

  const isDateColumn =
    columns.find((col) => col.field === selectedColumn)?.type === "date";

  return (
    <Box sx={{ display: "flex", alignItems: "center" }} className="mx-1">
      <FormControl
        variant="outlined"
        size="small"
        style={{ marginRight: "10px" }}
      >
        <InputLabel>Column</InputLabel>
        <Select
          value={selectedColumn}
          onChange={handleColumnChange}
          label="Column"
          className="min-w-[100px]"
        >
          {columns
            .filter((column) => column.field != "action")
            .map((column) => (
              <MenuItem key={column.field} value={column.field}>
                {column.headerName ? column.headerName : column.field}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      {isDateColumn ? (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <FormControl
            variant="outlined"
            size="small"
            style={{ marginRight: "10px" }}
          >
            <InputLabel>Operator</InputLabel>
            <Select
              value={dateOperator}
              onChange={handleOperatorChange}
              label="Operator"
              className="min-w-[100px]"
            >
              <MenuItem value="equals">Equals</MenuItem>
              <MenuItem value="before">Before</MenuItem>
              <MenuItem value="after">After</MenuItem>
            </Select>
          </FormControl>
          <DatePicker
            label="Date"
            value={dateValue}
            onChange={handleDateChange}
            format="dd/MM/yyyy"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                style={{ marginRight: "10px" }}
              />
            )}
          />
        </LocalizationProvider>
      ) : (
        <>
          <FormControl
            variant="outlined"
            size="small"
            style={{ marginRight: "10px" }}
          >
            <InputLabel>Operator</InputLabel>
          </FormControl>
          {columns.find((col) => col.field === selectedColumn).type ===
          "singleSelect" ? (
            <Select
              value={filterValue}
              onChange={(event) => handleFilterValueChange(event, "Select")}
              label="Column"
              className="min-w-[200px] h-10 mr-2 text-black"
            >
              {columns
                .find((col) => col.field === selectedColumn)
                .valueOptions.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
            </Select>
          ) : (
            <TextField
              label={`Filter by ${
                columns.find((col) => col.field === selectedColumn).headerName
                  ? columns.find((col) => col.field === selectedColumn)
                      .headerName
                  : columns.find((col) => col.field === selectedColumn).field
              }`}
              value={filterValue}
              onChange={handleFilterValueChange}
              variant="outlined"
              size="small"
              style={{ marginRight: "10px" }}
            />
          )}
        </>
      )}

      <Button
        sx={{
          backgroundColor: "#000000",
          color: "white",
          "&:hover": {
            backgroundColor: "#000000",
            opacity: 0.5,
          },
        }}
        onClick={applyFilter}
      >
        Apply Filter
      </Button>
      <Button
        sx={{
          marginLeft: "10px",
          color: "#000000",
          "&:hover": {
            backgroundColor: "#000000",
            color: "white",
            opacity: 0.8,
          },
        }}
        onClick={clearFilter}
      >
        Clear Filter
      </Button>
    </Box>
  );
}

export default function DataTable({
  rows,
  columns,
  onFilterChange,
  rowCount,
  paginationModel,
  onPaginationModelChange,
  onClearFilter,
  rowHeight,
  hideHeader = false,
  showFilter = true,
}) {
  //state
  const [filteredRows, setFilteredRows] = React.useState(rows);
  React.useEffect(() => {
    setFilteredRows(rows);
  }, [rows]);
  const handleFilterChange = (
    columnField,
    filterValue,
    operator,
    dateValue
  ) => {
    onFilterChange({
      column: columnField,
      filterValue: filterValue,
      operator: operator,
      dateValue: dateValue,
    });
  };

  const handleQuickFilterChange = (
    columnField,
    filterValue,
    operator,
    dateValue
  ) => {
    let newFilteredRows = rows;

    if (columns.find((col) => col.field === columnField)?.type === "date") {
      if (dateValue) {
        const date = new Date(dateValue);
        switch (operator) {
          case "equals":
            newFilteredRows = newFilteredRows.filter(
              (row) => row[columnField]?.toDateString() === date.toDateString()
            );
            break;
          case "before":
            newFilteredRows = newFilteredRows.filter(
              (row) => row[columnField] < date
            );
            break;
          case "after":
            newFilteredRows = newFilteredRows.filter(
              (row) => row[columnField] > date
            );
            break;
          default:
            break;
        }
      }
    } else {
      switch (operator) {
        case "contains":
          newFilteredRows = newFilteredRows.filter((row) =>
            row[columnField]
              ?.toString()
              .toLowerCase()
              .includes(filterValue.toLowerCase())
          );
          break;
        case "equals":
          newFilteredRows = newFilteredRows.filter(
            (row) =>
              row[columnField]?.toString().toLowerCase() ===
              filterValue.toLowerCase()
          );
          break;
        case "startsWith":
          newFilteredRows = newFilteredRows.filter((row) =>
            row[columnField]
              ?.toString()
              .toLowerCase()
              .startsWith(filterValue.toLowerCase())
          );
          break;
        case "endsWith":
          newFilteredRows = newFilteredRows.filter((row) =>
            row[columnField]
              ?.toString()
              .toLowerCase()
              .endsWith(filterValue.toLowerCase())
          );
          break;
        default:
          break;
      }
    }

    setFilteredRows(newFilteredRows);
  };

  const hideHeaderStyle = hideHeader
    ? {
        "& .MuiDataGrid-columnHeaders": {
          display: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          marginTop: "0 !important",
        },
      }
    : {};

  return (
    <>
      {showFilter && (
        <div className="flex flex-row h-[60px] ">
          <FilterPanel
            columns={columns}
            onFilterChange={handleFilterChange}
            filteredRows={filteredRows}
            setFilteredRows={setFilteredRows}
            handleQuickFilterChange={handleQuickFilterChange}
            onClearFilter={onClearFilter}
          />
        </div>
      )}
      <div className="shadow-lg rounded-2xl border-indigo-700 border-1  py-2 p-[10px] h-[90%] z-50">
        <DataGrid
          rows={filteredRows}
          columns={columns}
          rowCount={rowCount}
          paginationMode="server"
          initialState={{
            pagination: {
              paginationModel: paginationModel,
            },
          }}
          getRowHeight={(params) => {
            return rowHeight ? rowHeight : "";
          }}
          onPaginationModelChange={onPaginationModelChange}
          disableColumnFilter
          disableColumnMenu
          isRowSelectable={() => false}
          sx={hideHeaderStyle}
        />
      </div>
    </>
  );
}
