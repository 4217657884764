import { useContext, useState, useEffect, useCallback } from "react";
import { AppDataContext } from "../../../App.jsx";
import * as React from "react";
import { Button, Chip, Stack } from "@mui/material";
import { PageLoaderContext } from "../../utils/PageLoader.jsx";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { DataTableHeader, PagableFilter } from "../../../dto/ApplicationDTO.ts";
import { useOktaAuth } from "@okta/okta-react";
import useHttp from "../../../hooks/useHttp.js";
import { getArchiveAlerts } from "../../../service/Endpoints.js";
import { AlertDetailsRespose } from "../../../dto/ResponeDTO.ts";
import DataTable from "../common/DataTable.jsx";
import CancelIcon from "@mui/icons-material/Cancel";
import PersonIcon from "@mui/icons-material/Person";

function stringToLightColor(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xFF;
      value = Math.floor((value + 255) / 2);
      color += ('00' + value.toString(16)).slice(-2);
  }

  return color;
}

let dataTableHeader = [];
const dateTimeFormat = (dateString) => {
  const utcDate = new Date(dateString.toUTCString());
  const formattedDate =
    (utcDate.getUTCMonth() + 1).toString().padStart(2, "0") +
    "-" +
    utcDate.getUTCDate().toString().padStart(2, "0") +
    "-" +
    utcDate.getUTCFullYear();
  return formattedDate;
};
const headerStyle = "bg-primary-light text-primary-blue font-medium";
dataTableHeader.push(
  new DataTableHeader()
    .setField("id")
    .setHeaderName("AlertId")
    .setHeaderClassName(headerStyle)
    .setFlex(2)
);
dataTableHeader.push(
  new DataTableHeader()
    .setField("alertType")
    .setHeaderName("Type")
    .setHeaderClassName(headerStyle)
    .setFlex(1.2)
    .setRenderCell((params) => { 
      return (
      <div className="flex flex-row mt-5 ">
        <p className={`flex items-center justify-center rounded-xl w-36 text-purple-700 text-xs font-semibold px-4`} style={{backgroundColor:stringToLightColor(params.value)}}>
          {params.value}
        </p>
      </div>
    )})
);

dataTableHeader.push(
  new DataTableHeader()
    .setField("assignedTo")
    .setHeaderName("Assigned To")
    .setHeaderClassName(headerStyle)
    .setFlex(1)
    .setRenderCell((params) => (
      <Stack direction="row" alignItems="center" spacing={1}>
        <PersonIcon sx={{color:"#0000FF"}} />
        <p>{params.value}</p>
      </Stack>
    ))
);
dataTableHeader.push(
  new DataTableHeader()
    .setField("verdict")
    .setHeaderName("Verdict")
    .setHeaderClassName(headerStyle)
    .setFlex(1)
    .setType("singleSelect")
    .setValueOption(["FRAUD", "NOT FRAUD"])
    .setRenderCell((params) => (
      <Chip
        icon={
          params.value === "NOT FRAUD" ? (
            <CheckCircleIcon style={{ color: "green" }} />
          ) : params.value === "FRAUD" ? (
            <CancelIcon style={{ color: "red" }} />
          ) : (
            <NewReleases />
          )
        }
        label={params.value}
        color={
          params.value === "NOT FRAUD"
            ? "success"
            : params.value === "FRAUD"
            ? "warning"
            : "primary"
        }
        variant="outlined"
      />
    ))
);
dataTableHeader.push(
  new DataTableHeader()
    .setField("verdictOn")
    .setHeaderName("Verdict On")
    .setHeaderClassName(headerStyle)
    .setType("date")
    .setFlex(1)
    .setRenderCell((params) => {
      return (
        <>
          <p className="text-sm text-gray-500 font-medium pt-4">
            {params.value.toDateString("YYYY-MM-dd")}
          </p>
        </>
      );
    })
);
dataTableHeader.push(
  new DataTableHeader()
    .setField("createdOn")
    .setHeaderName("Created On")
    .setHeaderClassName(headerStyle)
    .setType("date")
    .setFlex(1)
    .setRenderCell((params) => {
      return (
        <>
          <p className="text-sm text-gray-500 font-medium pt-4">
            {params.value.toDateString("YYYY-MM-dd")}
          </p>
        </>
      );
    })
);


dataTableHeader.push(
  new DataTableHeader()
    .setField("action")
    .setHeaderName("")
    .setHeaderClassName(headerStyle)
    .setRenderCell((params) => (
      <Button
        sx={{
          color: "rgb(37,19,217)",
          "&:hover": {
            backgroundColor: "#737aff",
            opacity: 0.8,
          },
        }}
      >
        View Details
      </Button>
    ))
    .setFlex(0.9)
);

export default function History() {
  //context
  const selectTabContext = useContext(AppDataContext);
  selectTabContext.setSelectedTab(3);
  const pageLoaderContext = useContext(PageLoaderContext);

  //hooks
  const { isLoading, error, sendRequest: callApi } = useHttp();
  const { authState, oktaAuth } = useOktaAuth();

  //state---------------------------------------------------------------------------------
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });
  const [alertList, setAlertList] = useState(new AlertDetailsRespose());
  const [paginationFilter, setPaginationFilter] = useState(new PagableFilter());
  const [dataTableRows, setDataTableRows] = useState([]);
  const [showEditAlert, setShowEditAlert] = useState(false);
  //---------------------------------------------------------------------------------state

  //side effects---------------------------------------------------------------------------------
  useEffect(() => {
    pageLoaderContext.showLoader(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setPaginationFilter((prev) => {
      const newPagination = new PagableFilter();
      newPagination.offset =
        (paginationModel.page + 1) * paginationModel.pageSize -
        paginationModel.pageSize;
      newPagination.limit = paginationModel.pageSize;
      newPagination.filters = prev.filters;
      return newPagination;
    });
  }, [paginationModel.page]);

  useEffect(() => {
    if (paginationFilter.limit > -1 || paginationFilter.offset > -1)
      getAlertsFromApi(paginationFilter);
  }, [paginationFilter]);

  useEffect(() => {
    if (alertList.alerts) {
      let mapped = alertList.alerts.map((alert) => {
        return {
          id: alert.alertId,
          alertType: alert.alertType,
          assignedTo: alert.assignedTo,
          verdict: alert.verdict,
          verdictOn: new Date(alert.verdictOn),
          createdOn: new Date(alert.createdOn),
        };
      });
      setDataTableRows(mapped);
    }
  }, [alertList]);
  //---------------------------------------------------------------------------------side effects

  //functions---------------------------------------------------------------------------------
  const onFilterChange = useCallback(
    ({ column, filterValue, operator, dateValue }) => {
      const value = filterValue ? filterValue : dateTimeFormat(dateValue);
      setPaginationFilter((prev) => {
        const newPagination = new PagableFilter();
        newPagination.offset = 0;
        newPagination.limit = paginationModel.pageSize;
        newPagination.addFilter(column, value, operator);
        return newPagination;
      });
    },
    []
  );
  const onFilterClear = useCallback(() => {
    setPaginationFilter((prev) => {
      const newPagination = new PagableFilter();
      newPagination.filters = [];
      newPagination.offset = 0;
      newPagination.limit = paginationModel.pageSize;
      return newPagination;
    });
  }, []);
  //---------------------------------------------------------------------------------functions

  //API Calls---------------------------------------------------------------------------------
  const getAlertsFromApi = (pagableFilter) => {
    let token = authState.accessToken.accessToken;
    callApi(
      { url: getArchiveAlerts(pagableFilter) },
      (response) => {
        const responseBody = new AlertDetailsRespose();
        responseBody.applyData(response);
        console.log(responseBody);
        setAlertList(responseBody);
      },
      token
    );
  };
  //---------------------------------------------------------------------------------API Calls

  return (
      <div className="w-[90%] m-auto bg-white rounded-2xl p-3 h-[90%]">
        <DataTable
          rows={dataTableRows}
          columns={dataTableHeader}
          onFilterChange={onFilterChange}
          rowCount={alertList.totalRecords}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          onClearFilter={onFilterClear}
        />
      </div>
  );
}
