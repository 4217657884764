const endPoint = window._env_.REACT_APP_BACKEND_HOST

const createFilterString = (filters) => {
    let filterString = "";
  
    for (let filter of filters) {
      if (filterString) filterString = filterString + " AND ";
      let operator = "=";
      switch (filter.operator) {
        case "equals":
          operator = "=";
          break;
        case "contains":
          operator = "like";
          break;
        case "before":
          operator = "<";
          break;
        case "after":
          operator = ">";
          break;
        case "in":
          operator = "in";
          break;
        case "not":
          operator = "!=";
          break;
        default:
          operator = "=";
      }
      filterString = `${filterString}${filter.field} ${operator} ${filter.value}`;
    }
    return filterString;
  };

export function userLogin(){
    return `${endPoint}/login`
}

export function getAllAlerts(pagableFilter){
    let filterString = createFilterString(pagableFilter.filters);
    return `${endPoint}/allAlert?filter=${filterString}&limit=${pagableFilter.limit}&offset=${pagableFilter.offset}`
}

export function getArchiveAlerts(pagableFilter){
  let filterString = createFilterString(pagableFilter.filters);
  return `${endPoint}/allArchive?filter=${filterString}&limit=${pagableFilter.limit}&offset=${pagableFilter.offset}`
}

export function getAlertData(alertId){
    return `${endPoint}/data?alertId=${alertId}`
}

export function assignAlert(alertId){
    return `${endPoint}/assignAlert?alertId=${alertId}`
}

export function getMonthStats(monthId){
    return `${endPoint}/stats/monthly?month=${monthId}`
}

export function getTotalStats(){
    return `${endPoint}/stats/total`
}

export function getUsersList(){
    return `${endPoint}/user/userList`
}

export function getAlertTypes(){
    return `${endPoint}/getTypes`
}

export function setAlertVerdict(alertId,verdict){
    return `${endPoint}/setVerdict?alertId=${alertId}&verdict=${verdict}`
}

export function setUserFilter(){
  return `${endPoint}/user/filter`
}

export function getUserFilter(){
  return `${endPoint}/user/filter`
}